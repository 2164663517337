import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Logic chip design: Advanced CPUs`}</h4>
    <p>{`Central processing units ("CPUs") are the dominant general purpose logic chips. Two U.S. firms, Intel and AMD, have long held a duopoly over CPUs used for laptops, desktops, and servers. (China has several ventures, though none are competitive with U.S. firms.) CPUs are often classified into "nodes," which represent technology generations: a chip at a new node (e.g., "5 nm‚" released in 2020) contains approximately double the transistor density as a previous node (e.g., "7 nm‚" released in 2018) and is also more cost-effective.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      